import React from "react";
import { Carousel } from "react-bootstrap";
import "../../styles/home/Solutions.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Solutions = ({ solutions }) => {
  const baseURL = "https://digitalbackend.techsistltd.com";
  return (
    <div id="solutions" className="text-center my-5">
      <AnimationOnScroll
        animateIn="animate__zoomIn"
        animateOut="animate__zoomOut"
      >
        <h2 className="title my-5">Solutions</h2>
      </AnimationOnScroll>
      <div>
        <Carousel>
          {solutions?.data?.solution?.map(({ banner_image }) => (
            <Carousel.Item interval={1000}>
              <img
                className="d-block w-75 m-auto"
                src={`${baseURL}${banner_image}`}
                alt="First slide"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Solutions;
