import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";

// gallery stylesheet
import "../../styles/techsist/Gallery.scss";

// Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// Swiper core and required modules
import SwiperCore, { EffectCoverflow, Navigation, Pagination } from "swiper";

// Swiper modules
SwiperCore.use([EffectCoverflow, Navigation, Pagination]);

const Gallery = () => {
  const baseURL = "https://digitalbackend.techsistltd.com";
  const [gallery, setGallery] = useState();
  useEffect(() => {
    // API fetching
    fetch(`${baseURL}/gallery_lists/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setGallery(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div id="gallery">
      <Container>
        <h1 className="title my-4 text-center display-5 mb-5 heading">
          Gallery
        </h1>
        <div className="slideshow">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            initialSlide={2}
            breakpoints={{
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 4,
              },
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 150,
              modifier: 1,
              slideShadows: true,
            }}
            navigation={true}
            pagination={true}
            loop={true}
          >
            {/* map is a function, which is used to iterate over an array and
            manipulate or change data items for dynamic values */}
            {gallery?.status === false ? (
              <Card style={{ width: "auto" }}>
                <Card.Body>
                  <Card.Text as="h2" className="text-center">
                    Coming Soon!
                  </Card.Text>
                </Card.Body>
              </Card>
            ) : (
              gallery?.data[0]?.images?.map(({ image }) => (
                <SwiperSlide>
                  <img src={`${baseURL}${image}`} alt="Slider" />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>
        {/* gallery end */}
      </Container>
    </div>
  );
};

export default Gallery;
