import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "../styles/contact/Contact.scss";
import { FaPhone, FaMapPin } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { AnimationOnScroll } from "react-animation-on-scroll";
// react-hook-form
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const baseURL = "https://digitalbackend.techsistltd.com";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [contactDetails, setContactDetails] = useState({});
  const onSubmit = (data) => {
    fetch(`${baseURL}/drop_message/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.status) {
          Swal.fire(
            "Thank you!",
            "An executive from Techsist will get in touch with you shortly",
            "success"
          );
        } else {
          alert(data.msg);
        }

      })
      .catch((error) => console.error("Error -> ", error));
    reset();
  };
  useEffect(() => {
    /**
     * fetching data from company (/latest_company_details/) API
     * @param {string} baseURL
     * @param {string} endpoint
     * @param {object} setContactDetails
     */
    fetch(`${baseURL}/contact_details/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setContactDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="contact">
      <Container>
        <Row className="row">
          {/* start contact form */}
          <Col md={12} lg={6} className="d-flex align-items-center justify-content-center mb-3">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <Card className="contact-form">
                <Card.Body>
                  <h1 className="title display-5 text-center">
                    {contactDetails?.data?.contact?.title}
                  </h1>
                  <p className="text-white">
                    {contactDetails?.data?.contact?.description}
                  </p>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        {...register("email", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Description"
                        rows={3}
                        {...register("message", {
                          required: true,
                        })}
                      />
                    </Form.Group>
                    <Button type="submit" className="d-block mx-auto">Submit</Button>
                  </Form>
                </Card.Body>
              </Card>
            </AnimationOnScroll>
          </Col>
          {/* contact form end */}
          <Col md={12} lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <div className="google-map">
                {/* Google map integration by using Iframe */}
                <iframe
                  width="100%"
                  height="500"
                  id="gmap_canvas"
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1300.8321776792955!2d90.41264025594822!3d23.809583037722295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c78b0c47699b%3A0x1fbce4eb532a391c!2sTechsist%20Limited!5e0!3m2!1sen!2sbd!4v1640779368044!5m2!1sen!2sbd"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row className="contact-us py-5 text-center">
          {/* contact details start */}
          <Col md={4} className="mb-5">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <div>
                <div id="social-icon">
                  <section>
                    <ul>
                      <li>
                        <a href="#">
                          <FaPhone />
                        </a>
                      </li>
                    </ul>
                  </section>
                </div>
                <a
                  className="text-decoration-none text-white"
                  href={contactDetails?.data?.phone}
                >
                  {contactDetails?.data?.phone}
                </a>
              </div>
            </AnimationOnScroll>
          </Col>
          <Col md={4} className="mb-5">
            <AnimationOnScroll animateIn="animate__fadeInUp">
              <div>
                <div id="social-icon">
                  <section>
                    <ul>
                      <li>
                        <a href="#">
                          <MdMail />
                        </a>
                      </li>
                    </ul>
                  </section>
                </div>
                <a
                  className="text-decoration-none text-white"
                  href={contactDetails?.data?.email1}
                >
                  {contactDetails?.data?.email1}
                </a>
              </div>
            </AnimationOnScroll>
          </Col>
          <Col md={4}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <div>
                <div id="social-icon">
                  <section>
                    <ul>
                      <li>
                        <a href="#">
                          <FaMapPin />
                        </a>
                      </li>
                    </ul>
                  </section>
                </div>
                <a className="text-decoration-none text-white" href="#">
                  {contactDetails?.data?.address}
                </a>
              </div>
            </AnimationOnScroll>
          </Col>
          {/* contact details end */}
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
