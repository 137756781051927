import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../styles/solutions/Solutions.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const baseURL = "https://digitalbackend.techsistltd.com";

const Solutions = () => {
  const [solutions, setSolutions] = useState({});
  useEffect(() => {
    // API fetching
    fetch(`${baseURL}/solution_lists/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setSolutions(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  if (!`${baseURL}${solutions?.data?.image}`) {
    return <h1>Loading...</h1>;
  }
  return (
    <div className="solutions">
      <div>
        <LazyLoadImage
          effect="blur"
          src={`${baseURL}${solutions?.data?.image}`}
          alt=""
          className="img-fluid banner-image"
          height={`${baseURL}${solutions?.data?.image}`.height}
          width="100%"
        />
      </div>
      <Container>
        <div>
          {/* map is a function, which is used to iterate over an array and
            manipulate or change data items for dynamic values */}
          {solutions?.data?.solution?.map(
            (
              { poster_image, title, description, file, button_text, id },
              index
            ) => {
              if (index % 2 === 0) {
                return (
                  <Row className="flex-sm-row-reverse" key={id} >
                    <Col md={6} className="my-3">
                      <AnimationOnScroll animateIn="animate__fadeInRight">
                        <LazyLoadImage
                          effect="blur"
                          className="img-fluid p-5"
                          src={`${baseURL}${poster_image}`}
                          alt="logo"
                          height={`${baseURL}${solutions?.data?.image}`.height}
                          width={`${baseURL}${solutions?.data?.image}`.width}
                        />
                      </AnimationOnScroll>
                    </Col>
                    <Col md={6} className="d-flex align-items-center">
                      <AnimationOnScroll animateIn="animate__fadeInLeft">
                        <div className="text-center">
                          <svg
                            width="46"
                            height="45"
                            viewBox="0 0 46 45"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.0664062 8C0.0664062 3.58172 3.64813 0 8.06641 0H37.1908C41.6091 0 45.1908 3.58172 45.1908 8V37C45.1908 41.4183 41.6091 45 37.1908 45H8.06641C3.64813 45 0.0664062 41.4183 0.0664062 37V8Z"
                              fill="#3F69FA"
                            />
                            <path
                              d="M22.6286 15.25C18.3474 15.25 14.8786 18.7188 14.8786 23C14.8786 27.2812 18.3474 30.75 22.6286 30.75C26.9099 30.75 30.3786 27.2812 30.3786 23C30.3786 18.7188 26.9099 15.25 22.6286 15.25Z"
                              fill="white"
                            />
                          </svg>
                          <h1 className="solution-title my-3">{title}</h1>
                          <p className="text-white">{description}</p>
                          {file == null ? (
                            <Button
                              as="a"
                              target="_blank"
                              href="#"
                              className="text-white"
                            >
                              {button_text}
                            </Button>
                          ) : (
                            <Button
                              as="a"
                              target="_blank"
                              href={`${baseURL}${file}`}
                              className="text-white"
                            >
                              {button_text}
                            </Button>
                          )}
                        </div>
                      </AnimationOnScroll>
                    </Col>
                  </Row>
                );
              } else {
                return (
                  <Row>
                    <Col md={6} className="my-3">
                      <AnimationOnScroll animateIn="animate__fadeInLeft">
                        <LazyLoadImage
                          effect="blur"
                          className="img-fluid p-5"
                          src={`${baseURL}${poster_image}`}
                          alt="logo"
                          height={`${baseURL}${solutions?.data?.image}`.height}
                          width={`${baseURL}${solutions?.data?.image}`.width}
                        />
                      </AnimationOnScroll>
                    </Col>
                    <Col md={6} className="d-flex align-items-center">
                      <AnimationOnScroll animateIn="animate__fadeInRight">
                        <div className="text-center">
                          <svg
                            width="46"
                            height="45"
                            viewBox="0 0 46 45"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.0664062 8C0.0664062 3.58172 3.64813 0 8.06641 0H37.1908C41.6091 0 45.1908 3.58172 45.1908 8V37C45.1908 41.4183 41.6091 45 37.1908 45H8.06641C3.64813 45 0.0664062 41.4183 0.0664062 37V8Z"
                              fill="#3F69FA"
                            />
                            <path
                              d="M22.6286 15.25C18.3474 15.25 14.8786 18.7188 14.8786 23C14.8786 27.2812 18.3474 30.75 22.6286 30.75C26.9099 30.75 30.3786 27.2812 30.3786 23C30.3786 18.7188 26.9099 15.25 22.6286 15.25Z"
                              fill="white"
                            />
                          </svg>
                          <h1 className="solution-title my-3">{title}</h1>
                          <p className="text-white">{description}</p>
                          {file == null ? (
                            <Button
                              as="a"
                              target="_blank"
                              href="#"
                              className="text-white"
                            >
                              {button_text}
                            </Button>
                          ) : (
                            <Button
                              as="a"
                              target="_blank"
                              href={`${baseURL}${file}`}
                              className="text-white"
                            >
                              {button_text}
                            </Button>
                          )}
                        </div>
                      </AnimationOnScroll>
                    </Col>
                  </Row>
                );
              }
            }
          )}
        </div>
      </Container>
    </div>
  );
};

export default Solutions;
