import React from 'react';
import anni from "../../assets/images/annimation.gif";
import "./ContactUs.scss";

const ContactUs = () => {
    return (
        <div className="container py-5 mt-lg-5" id='contact-container'>
            <div className="contact-container  mx-auto row align-items-center p-4   ">
                <div className="col-12 col-sm-12 col-md-7 col-lg-9 lets-collaborate-text">
                    <h1>Let’s Collaborate and</h1>
                    <h2>Build something great</h2>
                </div>
                <div className="col-12 col-sm-12 col-md-5 col-lg-3 lets-collaborate-contact-box d-flex align-items-center justify-content-between">
                    <span>Contact</span>
                    <img src={anni} alt="" className='img-fluid ' />
                </div>
            </div>
        </div>
    );
};

export default ContactUs;