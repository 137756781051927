import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageFlip from "../../components/portfolio/PageFlip";
import "../../styles/techsist/Portfolio.scss";
import "../../styles/portfolio/PageFlip.scss";

import { AnimationOnScroll } from "react-animation-on-scroll";

// Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import ContactForm from "../../components/ContactForm/ContactForm";
import ContactUs from "../../components/ContactUs/ContactUs";
import OurRecentProjects from "./OurRecentProjects/OurRecentProjects";

// Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Portfolio = () => {
  const baseURL = "https://digitalbackend.techsistltd.com";

  const [portfolio, setPortfolio] = useState({});

  useEffect(() => {
    // API fetching
    fetch(`${baseURL}/portfolio_lists/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {

        setPortfolio(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div id="work-engagement">
        <Container>
          <Row className="solutions-banner">
            <Col
              md={3}
              className="d-flex align-items-center justify-content-center"
            >
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <div className="portfolio-title-border text-center">
                  <div>
                    <h1 className="title fw-bold my-3">
                      {portfolio?.data?.title}
                    </h1>
                    <p className="text-white">{portfolio?.data?.description}</p>
                  </div>
                </div>
              </AnimationOnScroll>
            </Col>
            <Col md={9}>{portfolio?.data && <PageFlip pfolio={portfolio} />}</Col>
          </Row>
          <div className="text-center">
            <h1 className="title display-4 fw-bold">
              {portfolio?.data?.clients?.title}
            </h1>
            <p className="text-white mb-5 pb-5 text-center">
              {portfolio?.data?.clients?.description}
            </p>
            <Swiper
              grabCursor={true}
              centeredSlides={true}
              preloadImages={true}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 4,
                },
              }}
              // navigation={true}
              pagination={{
                clickable: true,
              }}
              loop={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              simulateTouch={false}
            >
              {portfolio?.data?.clients?.client_description?.map(
                ({ logo }, index) => (
                  <SwiperSlide key={index} className="pb-4">
                    <div>
                      <img src={`${baseURL}${logo}`} alt="logo" />
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </Container>
      </div>
      <OurRecentProjects portfolio={portfolio}></OurRecentProjects>
      <ContactForm></ContactForm>
      {/* <ContactUs></ContactUs> */}
    </>
  );
};

export default Portfolio;
