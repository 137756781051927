import React, { useState } from 'react';
import './MeetingDetails.css';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import JoditEditor from "jodit-react";
import img from '../../assets/images/meeting-vector-img.png';
import Swal from 'sweetalert2';

const MeetingDetails = ({ meetingData, setMeetingData, projectName, setProjectName, meetingPartcipants, setMeetingPartcipants, meetingDiscussion, setMeetingDiscussion, meetingOutcomes, setMeetingOutcomes }) => {

    const meetingPartcipantsEditor = useRef(null);
    const meetingDiscussionEditor = useRef(null);
    const meetingOutcomesEditor = useRef(null);

    const [meetingOutcomess, setMeetingOutcomess] = useState("");
    const [meetingPartcipantss, setMeetingPartcipantss] = useState("");
    const [meetingDiscussionss, setMeetingDiscussionss] = useState("");

    // console.log(meetingOutcomess);
    // console.log(meetingPartcipantss);
    // console.log(meetingPartcipantss);

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { register, handleSubmit, reset } = useForm();
    const onSubmit = data => {
        const formData = new FormData();
        formData.append("location", data.location);
        formData.append("project_name", data.project_name);
        formData.append("meeting_participate", meetingPartcipantss);
        formData.append("meeting_discussion", meetingDiscussionss);
        formData.append("meeting_outcomes", meetingOutcomess);
        // formData.append("digital_profile", data.digital_profile[0]);

        fetch(`https://digitalbackend.techsistltd.com/meeting_details/`, {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                // console.log(response)
                if (response.ok) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Information Saved'
                    })
                }
                else {
                    alert("try again!!")
                }

            })
            // .then((data) => {
            //     console.log(data);
            //     alert(data?.msg);
            //     Toast.fire({
            //         icon: 'success',
            //         title: 'Information Saved'
            //     })
            //     reset();
            // })
            .catch((error) => console.error("Error -> ", error));
    }
    // console.log(meetingData)

    return (
        <div className='container mt-5 '>
            <div className='row d-flex justify-content-center align-items-center '>
                <div className='col-12 col-sm-12 col-md-6 col-lg-7 text-start '>

                    <form onSubmit={handleSubmit(onSubmit)} className="">

                        <h2 className='text-white mb-3 client-name'>Client Name :  {meetingData.name}</h2>
                        <br />

                        <h2 className='text-white mb-3 client-name'> Meeting Location</h2>
                        <input className='mb-4 ps-2 editors-background'   {...register("location")}
                            name="location"
                            placeholder="Location"
                            value={meetingData.location}
                            onChange={(e) => {
                                setMeetingData({ ...meetingData, location: e.target.value })
                            }} />

                        <h2 className='text-white mb-3 client-name'>Meeting Time</h2>
                        <input className='mb-4 ps-2 editors-background' {...register("meetingTime")} placeholder="Meeting Time"
                            value={meetingData.meetingTime}
                            onBlur={(e) => {
                                setMeetingData({ ...meetingData, meetingTime: e.target.value })
                            }} />

                        <h2 className='meeting-text'>Project Name:</h2>
                        <div className='editor-background'>

                            <input className=' ps-2 editors-background' {...register("project_name")}

                                placeholder="Project Name"
                                value={meetingData.project_name}
                                onChange={(e) => {
                                    // console.log(e.target.value)
                                    setMeetingData({ ...meetingData, project_name: e.target.value })
                                }} />


                        </div>

                        <br />


                        <h2 className='meeting-text'>Meeting Partcipants:</h2>
                        <div className='editor-background'>
                            {/* <textarea className=' ps-2 editors-background' {...register("meeting_participate")} name="meeting_participate"
                                placeholder="Meeting Partcipants"
                                value={meetingData.meeting_participate}
                                onBlur={(e) => {
                                    setMeetingData({ ...meetingData, meeting_participate: e.target.value })
                                }} /> */}
                            <JoditEditor
                                ref={meetingPartcipantsEditor}
                                value={meetingPartcipantss}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(participant) => {
                                    setMeetingPartcipantss(participant);
                                }}
                            />
                        </div>




                        <br />

                        <h2 className='meeting-text'>Meeting Discussion:</h2>
                        <div className='editor-background'>
                            {/* <textarea className=' ps-2 editors-background' {...register("meeting_discussion")} placeholder="Meeting Discussion"
                                name='meeting_discussion'
                                value={meetingDiscussionss}
                                onBlur={(e) => {
                                    setMeetingData({ ...meetingData, meeting_discussion: e.target.value })
                                }} /> */}
                            <JoditEditor
                                ref={meetingDiscussionEditor}
                                value={meetingDiscussionss}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(disccussion) => {
                                    setMeetingDiscussionss(disccussion);
                                }}
                            />
                        </div>


                        <br />
                        <br />
                        <h2 className='meeting-text'>Meeting Outcomes:</h2>
                        <div className='editor-background'>
                            {/* <textarea className=' ps-2 editors-background' {...register("meeting_outcomes")}
                                name="meeting_outcomes"
                                placeholder="Meeting Outcomes"
                                rows="3"
                                cols="50"
                                value={meetingOutcomess}
                                onBlur={(e) => {
                                    setMeetingData({ ...meetingData, meeting_outcomes: e.target.value })
                                }} /> */}
                            <JoditEditor
                                ref={meetingOutcomesEditor}
                                value={meetingOutcomess}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(outComes) => {
                                    setMeetingOutcomess(outComes);
                                }}
                            />
                        </div>
                        <br />
                        <input id="next" type="Submit" placeholder='' value="Submit" className='submit-btn p-3' />

                    </form>

                    <div className='mt-2'>
                        <a href="https://digitalbackend.techsistltd.com/generate_pdf/"  >Download</a>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-lg-5'>
                    <img className='img-fluid p-3' src={img} alt="" />

                </div>

            </div>

        </div>
    );
};

export default MeetingDetails;