import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ContactUs from '../../components/ContactUs/ContactUs';
import Trending from '../../components/Trending/Trending';
import BlogsBanner from './BlogsBanner/BlogsBanner';
import BlogsCategoryBlogViews from './BlogsCategoryBlogViews/BlogsCategoryBlogViews';
import LatestBlogs from './LatestBlogs/LatestBlogs';
import TopContributors from './TopContributors/TopContributors';

// imported Spinner from react-loader-Spinner
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Triangle } from "react-loader-spinner";

const Blogs = () => {
    const [blogs, setBlogs] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    console.log(blogs);

    useEffect(() => {
        setIsLoading(true);
        fetch("https://digitalbackend.techsistltd.com/blog_list_details/")
            .then(res => res.json())
            .then(data => setBlogs(data?.data))
            .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) {
        return <div
            style={{ height: "80vh", width: "100%" }}
            className="d-flex align-items-center justify-content-center"
        >
            <Triangle color="#237bb3" height={80} width={80} />
            Please wait... <br />
            Video is Loading...
        </div>
    }

    return (
        <>
            <BlogsBanner>{blogs}</BlogsBanner>
            <LatestBlogs blogs={blogs}></LatestBlogs>
            <BlogsCategoryBlogViews blogs={blogs}></BlogsCategoryBlogViews>
            <TopContributors blogs={blogs}></TopContributors>
            <Trending ></Trending>
            <ContactUs ></ContactUs>
        </>
    );
};

export default Blogs;