import React, { useState, useEffect, Suspense, lazy } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

// imported react-router-dom
import { Link } from "react-router-dom";

// imported react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";

// imported Home components (Banner, Services, Solutions, Projects)
// import Banner from '../components/home/Banner';
import Projects from "../components/home/Projects";
import Services from "../components/home/Services";
import Solutions from "../components/home/Solutions";

// Home stylesheet
import "../styles/home/Home.scss";

//React Lazy loading
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// imported Spinner from react-loader-Spinner
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Triangle } from "react-loader-spinner";

// baseURL
const baseURL = "https://digitalbackend.techsistltd.com";

//Lazy loader - imported Banner
const Banner = lazy(() => import("../components/home/Banner"));

const Home = () => {
  const [home, setHome] = useState({});
  const [services, setServices] = useState({});
  const [solutions, setSolutions] = useState({});
  useEffect(() => {
    /**
     * fetching data from company (/latest_company_details/) API
     * @param {string} baseURL
     * @param {string} endpoint
     * @param {object} setHome
     */
    fetch(`${baseURL}/latest_company_details/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => setHome(data))
      .catch((error) => console.log(error));

    /**
     * fetching data from services (/service_lists/) API
     * @param {string} baseURL
     * @param {string} endpoint
     * @param {object} setSolutions
     */
    fetch(`${baseURL}/service_lists/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => setServices(data))
      .catch((error) => console.log(error));

    /**
     * fetching data from solutions (/solution_lists/) API
     * @param {string} baseURL
     * @param {string} endpoint
     * @param {object} setSolutions
     */
    fetch(`${baseURL}/solution_lists/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => setSolutions(data))
      .catch((error) => console.log(error));
  }, []);
  return (
    <div id="home">
      {/* banner section start */}

      <Suspense
        fallback={
          <div
            style={{ height: "80vh", width: "100%" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Triangle color="#237bb3" height={80} width={80} />
            Please wait... <br />
            Video is Loading...
          </div>
        }
      >
        <Banner video={home?.data?.slider[0]?.video} />
      </Suspense>
      {/* banner section end */}
      {/* services section start */}
      <LazyLoadComponent>
        <Services services={services} />
      </LazyLoadComponent>
      {/* services section end */}
      {/* solutions section start */}
      <LazyLoadComponent>
        <Solutions solutions={solutions} />
      </LazyLoadComponent>
      {/* solutions section end */}
      {/* flagship project start */}
      <LazyLoadComponent>
        <Projects flagship_project={home?.data?.flagship_project} />
      </LazyLoadComponent>
      {/* flagship project end */}
      {/* home page footer start */}
      <footer id="footer" className="mt-5 pt-5">
        <Container className="mt-5">
          <Row>
            {/* first column start */}
            <Col md={4}>
              <AnimationOnScroll animateIn="animate__fadeInUp">
                <h4 className="mb-3">
                  {home?.data?.footer?.title}
                  <div className="underline">
                    <span></span>
                  </div>
                </h4>
                <p className="pb-2">{home?.data?.footer?.description}</p>
              </AnimationOnScroll>
            </Col>
            {/* first column end */}

            <Col md={1}></Col>

            {/* second column start */}
            <Col md={2}>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={300}>
                <h4 className="mb-3">
                  Services
                  <div className="underline">
                    <span></span>
                  </div>
                </h4>
                <ul className="text-light pb-1">
                  <li className="link"><a className="text-decoration-none text-light" href="http://it.techsistltd.com/" target="_blank" rel="noreferrer">IT Services</a></li>
                  <li className="link "><a className="text-decoration-none text-light" href="http://business.techsistltd.com/" target="_blank" rel="noreferrer">Business Services</a></li>
                  <li className="link "><a className="text-decoration-none text-light" href="http://digital.techsistltd.com/" target="_blank" rel="noreferrer">Digital Services</a></li>
                  <li className="link"><a className="text-decoration-none text-light" href="http://lifestyle.techsistltd.com/" target="_blank" rel="noreferrer">Lifestyle Services</a></li>
                  <li className="link"><a className="text-decoration-none text-light" href="http://studio.techsistltd.com/" target="_blank" rel="noreferrer">Studio Services</a></li>
                  <li className="link"><a className="text-decoration-none text-light" href="http://event.techsistltd.com/" target="_blank" rel="noreferrer">Event Services</a></li>


                </ul>
                {/* <ul>
                  {services?.data?.service_group?.map(({ title, slug }) => (
                    <li>
                      <Link className="link" to="/services" state={{ slug }}>
                        {`${title}`}
                      </Link>
                    </li>
                  ))}
                </ul> */}

              </AnimationOnScroll>
            </Col>
            {/* second column end */}

            <Col md={1}></Col>

            {/* third column start */}
            <Col md={2}>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={600}>
                <h4 className="mb-3">
                  About{" "}
                  <div className="underline">
                    <span></span>
                  </div>
                </h4>
                <ul className="pb-2">
                  <li>
                    <Link className="link" to="/about">
                      Our Story
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/about#vision">
                      Vision
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/a-team">
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/careers">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <a
                      className="link"
                      href="https://digitalbackend.techsistltd.com/account/login/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Official
                    </a>
                  </li>
                  <li>

                  </li>
                  <Link
                    to="/meetingMinutes"
                    className="text-decoration-none text-light"
                  >
                    Techsist x Client Meeting
                  </Link>
                </ul>
              </AnimationOnScroll>
            </Col>
            {/* third column end */}

            {/* social links column start */}
            <Col md={2}>
              <AnimationOnScroll animateIn="animate__fadeInUp" delay={900}>
                <h4 className="mb-3">
                  Follow Us{" "}
                  <div className="underline">
                    <span></span>
                  </div>
                </h4>
                <ul className="social-icons">
                  {home?.data?.social_media?.map(({ id, title, url, icon }) => (
                    <li key={id}>
                      <a
                        className="link"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={`fab fa-${title.toLowerCase()} me-3`}></i>{" "}
                        {title?.charAt(0).toUpperCase() + title?.slice(1)}
                      </a>
                    </li>
                  ))}
                </ul>
              </AnimationOnScroll>
            </Col>
            {/* social links column end */}
          </Row>
        </Container>
      </footer>
      {/* home page footer end */}
    </div>
  );
};

export default Home;
