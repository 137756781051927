import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// react bootstrap styles
import "bootstrap/dist/css/bootstrap.min.css";

// animate css
import "animate.css/animate.min.css";

//instagram feed css
import "react-ig-feed/dist/index.css";

// on route change scroll to top
import ScrollToTop from "./ScrollToTop";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
