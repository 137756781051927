import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ServiceCategories from './ServiceCategories';
import ServiceCol from './ServiceCol';

const baseURL = 'https://digitalbackend.techsistltd.com';

const ServicesTab = ({ service_description, setSubServices }) => {
  // const [activeTab, setActiveTab] = useState(null);
  return (
    <Row xs={1} md={2} className='g-4'>
      {service_description?.map((serviceDesc, idx) => (
        <ServiceCol key={idx} serviceDesc={serviceDesc} setSubServices={setSubServices} />
      ))}
    </Row>
  );
};

export default ServicesTab;
