import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import "../styles/careers/Careers.scss";
import JobCircular from "../components/careers/JobCircular";
import { AnimationOnScroll } from "react-animation-on-scroll";

const baseURL = "https://digitalbackend.techsistltd.com";

const Careers = () => {
  const [career, setCareer] = useState({});
  useEffect(() => {
    /**
     * fetching data from company (/job_circular_list/) API
     * @param {string} baseURL
     * @param {string} endpoint
     * @param {object} setCareer
     */
    fetch(`${baseURL}/job_circular_list/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setCareer(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Container>
      <div className="careers">
        <Row className="flex-sm-row-reverse d-flex align-items-center g-4 mt-1 mt-md-3">
          <Col md={6} className="px-5">
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <img
                className="img-fluid"
                src={`${baseURL}${career?.data?.image}`}
                alt="product_image"
              />
            </AnimationOnScroll>
          </Col>
          <Col md={6} className="">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              {/* <div> */}
              <h1 className="title product-title display-5 text-center">
                {career?.data?.title}
              </h1>
              <p className="service-description text-white">
                {career?.data?.description}
              </p>
              {/* </div> */}
            </AnimationOnScroll>
          </Col>
        </Row>
        <div className="my-5">
          <h1 className="title display-5 text-center my-5">Open Positions</h1>
          {career?.data?.job_circular.length === 0 ? (
            <Card style={{ width: "auto" }}>
              <Card.Body>
                <Card.Text className="text-center">
                  Sorry, there are no positions available at this time.
                </Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <Accordion className="bg-transparent">
              {career?.data?.job_circular.map((job) => (
                <JobCircular key={job?.id} job={job} baseURL={baseURL} />
              ))}
            </Accordion>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Careers;
