import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../../styles/about/About.scss";

const baseURL = "https://digitalbackend.techsistltd.com";

const About = () => {
  const [aboutDetails, setAboutDetails] = useState({});
  useEffect(() => {
    // API fetching
    fetch(`${baseURL}/company_about_us_details/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setAboutDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div id="about" className="about-page">
      <div className="banner text-center py-3">
        <div className="my-5">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <h1 className="title py-3">{aboutDetails?.data?.title}</h1>
          </AnimationOnScroll>
          <p className="title-description text-white">
            {aboutDetails?.data?.short_description}
          </p>
        </div>
      </div>
      {/* about us section start */}
      <div className="about my-5 p-5 parallax-effect-ourStory">
        <Container>
          <Row className="flex-sm-row-reverse our-story d-flex align-items-center our-story-responsive">
            <Col md={3}>
              <AnimationOnScroll animateIn="animate__fadeInRight">
                <h2 className="sub-title text-white text-center title-border">
                  {aboutDetails?.data?.title2}
                </h2>
              </AnimationOnScroll>
            </Col>
            <Col md={9}>
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <p className="text-center text-white">
                  {aboutDetails?.data?.description}
                </p>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </div>
      {/* about us section end */}

      {/* mission section start */}
      <div
        className="mission p-5 parallax-effect-mission"
        style={{
          marginTop: "110px",
          marginBottom: "75px",
        }}
      >
        <Container className="my-3">
          <Row>
            <Col md={4}>
              <AnimationOnScroll animateIn="animate__fadeInDown">
                <img
                  src={`${baseURL}${aboutDetails?.data?.mission?.image}`}
                  alt=""
                  className="img-fluid"
                />
              </AnimationOnScroll>
            </Col>
            <Col
              md={8}
              className="d-flex align-items-center justify-content-center"
            >
              <AnimationOnScroll animateIn="animate__fadeInUp">
                <p className="text-white">
                  {aboutDetails?.data?.mission?.description}
                </p>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </div>
      {/* mission section end */}

      {/* vision section start */}
      <div
        className="vision p-4 parallax-effect-vision"
        style={{
          marginTop: "75px",
          marginBottom: "75px",
          backgroundColor: "#E9F0F8",
        }}
      >
        <div className="my-5 text-center text-white">
          <AnimationOnScroll animateIn="animate__zoomIn">
            <h1 className="title display-4 text-white title-border-vision">
              {aboutDetails?.data?.vision?.title}
            </h1>
          </AnimationOnScroll>
          <p className="text-white">
            {aboutDetails?.data?.vision?.description}
          </p>
        </div>
        <Container>
          {aboutDetails?.data?.vision?.vision_description?.map(
            ({ title, image, description, id }) => (
              <Row className="d-flex align-items-center" key={id}>
                <Col md={3}>
                  <AnimationOnScroll animateIn="animate__fadeInLeft">
                    <img
                      src={`${baseURL}${image}`}
                      alt=""
                      className="vision-image"
                    />
                  </AnimationOnScroll>
                </Col>
                <Col md={9} className="p-5">
                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <h1 className="vision-title text-uppercase text-white text-center">
                      {title}
                    </h1>
                    <p className="text-center my-4 text-white">{description}</p>
                  </AnimationOnScroll>
                </Col>
              </Row>
            )
          )}
        </Container>
      </div>
      {/* vision section end */}

      {/* Work Engagement Process Start */}
      <div className="working-process text-center my-5">
        <AnimationOnScroll animateIn="animate__fadeInTopRight">
          <h1 className="text-white display-3 title work-engagement">
            {aboutDetails?.data?.work_engagement?.title}
          </h1>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <img
            src={`${baseURL}${aboutDetails?.data?.work_engagement?.image}`}
            alt="work-engagement-process"
          />
        </AnimationOnScroll>
      </div>
      {/* Work Engagement Process End */}
    </div>
  );
};

export default About;
