import React from 'react';
import BlogCategory from './BlogCategory/BlogCategory';
import BlogsViewsCard from './BlogsViews/BlogsViewsCard/BlogsViewsCard';

const BlogsCategoryBlogViews = ({ blogs }) => {

    return (
        <div className="container-fluid">
            <div className="container py-5">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <BlogCategory blogs={blogs}></BlogCategory>
                    {blogs?.top_views_page?.map((top_blog, index) => <BlogsViewsCard
                        key={top_blog.id}
                        top_blog={top_blog}
                    ></BlogsViewsCard>)}

                </div>
            </div>
        </div>
    );
};

export default BlogsCategoryBlogViews;