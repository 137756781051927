import React, { useState, useEffect } from "react";

// react-bootstrap
import { Container, Row, Col, Card } from "react-bootstrap";

// react-icons for Facebook, LinkedIn, Instagram icon
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";

// react animation on scroll
import { AnimationOnScroll } from "react-animation-on-scroll";

// react typist animation
import Typist from "react-typist";

// ATeam stylesheet
import "../../styles/techsist/ATeam.scss";
import { Link } from "react-router-dom";

const baseURL = "https://digitalbackend.techsistltd.com";

const ATeam = () => {
  const [member, setMembers] = useState({});
  console.log(member?.data?.consultant_lists);
  useEffect(() => {
    // API fetching from employee list
    fetch(`${baseURL}/team_member_deatils/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setMembers(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div id="a-team" className="mt-5">
      <Container>
        <Typist
          className="title text-uppercase text-center display-3  my-1 py-3"
          cursor={{ show: false }}
          avgTypingDelay={100}
        >
          <span
            style={{
              fontFamily: "Poppins",
              fontWeight: "500",
              display: "block",
              margin: "auto",
            }}
          >
            THE A TEAM
          </span>
        </Typist>
        {/* <h1
          className="text-uppercase text-center display-3 fw-bold my-1 py-5"
          style={{ letterSpacing: "15px" }}
        >
          A Team
        </h1> */}
        <div className="founding-members">
          <h1 className="brain-behind text-center text-capitalize">
            Brains Behind The Scene
          </h1>
          {/* map is a function, which is used to iterate over an array and manipulate or
          change data items for dynamic values */}
          {member?.data?.top_management_lists?.map(
            ({ name, image, description, designation, social_icon }, index) => {
              if (index % 2 === 0) {
                return (
                  <Row className="my-5 ">
                    <Col md={4}>
                      <AnimationOnScroll animateIn="animate__fadeInLeft">
                        <div className="text-center">
                          <h2 className="text-capitalize">{name}</h2>
                          <h6 className="mb-4 text-white">{designation}</h6>
                          <img
                            src={`${baseURL}${image}`}
                            alt="portrait"
                            className=""
                          />
                          <div className="social-icons text-center">
                            <div id="social-icon">
                              <section>
                                <ul>
                                  {social_icon.length ? (
                                    social_icon?.map(
                                      ({ id, title, icon, url }, index) => (
                                        <li key={id}>
                                          <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <i
                                              className={`fab fa-${title.toLowerCase()}`}
                                            ></i>
                                          </a>
                                        </li>
                                      )
                                    )
                                  ) : (
                                    <>
                                      <li>
                                        <a
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="fab fa-facebook"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="fab fa-linkedin"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="fab fa-instagram"></i>
                                        </a>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </section>
                            </div>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </Col>
                    <Col md={8} className="d-flex align-items-center">
                      <AnimationOnScroll animateIn="animate__fadeInRight">
                        <p className="ms-5">{description}</p>
                      </AnimationOnScroll>
                    </Col>
                  </Row>
                );
              } else {
                return (
                  <Row className="my-5 flex-sm-row-reverse">
                    <Col md={4}>
                      <AnimationOnScroll animateIn="animate__fadeInRight">
                        <div className="text-center">
                          <h2 className="w-100 text-capitalize">{name}</h2>
                          <h6 className="mb-4 text-white">{designation}</h6>
                          <img src={`${baseURL}${image}`} alt="portrait" />
                          <div className="social-icons text-center">
                            <div id="social-icon">
                              <section>
                                <ul>
                                  {social_icon.length ? (
                                    social_icon?.map(
                                      ({ id, title, icon, url }) => (
                                        <li key={id}>
                                          <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <i
                                              className={`fab fa-${title.toLowerCase()}`}
                                            ></i>
                                          </a>
                                        </li>
                                      )
                                    )
                                  ) : (
                                    <>
                                      <li>
                                        <a
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="fab fa-facebook"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="fab fa-linkedin"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="#"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <i className="fab fa-instagram"></i>
                                        </a>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </section>
                            </div>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </Col>
                    <Col md={8} className="d-flex align-items-center">
                      <AnimationOnScroll animateIn="animate__fadeInLeft">
                        <p className="me-5">{description}</p>
                      </AnimationOnScroll>
                    </Col>
                  </Row>
                );
              }
            }
          )}
        </div>
        {/* Founding Member end */}

        {/* employee start */}
        <div className="members mt-5 pt-5">
          <h1 className="members-title text-center display-5 text-uppercase">
            Members
          </h1>
          {/* map is a function, which is used to iterate over an array and manipulate or
          change data items for dynamic values */}
          <div className="mt-5 pt-5">
            <Row className="g-4">
              {member?.data?.consultant_lists?.map(
                ({ name, image, designation, social_icon, slug }) => (
                  <Col sm={6} lg={3} className="image-top">
                    <Card className="bg-transparent ">
                      <Card.Img
                        variant="top"
                        src={`${baseURL}${image}`}
                        className="member-image rounded p-3 mx-auto"
                      />
                      <Card.Body className="text-center">
                        {/* <Link
                          to={`/a-team/${slug}`}
                          className="text-decoration-none"
                        >
                          
                        </Link> */}
                        <Card.Title className="members-name-title h-50 text-decoration-none">
                          {name}
                        </Card.Title>
                        <Card.Text
                          as={"h6"}
                          className="text-white mt-3 designation"
                        >
                          {designation}
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className="border-0 social-icons text-center">
                        {social_icon.length ? (
                          social_icon?.map(({ id, title, icon, url }) => (
                            <Card.Link
                              key={id}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i
                                className={`fab fa-${title.toLowerCase()}`}
                              ></i>
                            </Card.Link>
                          ))
                        ) : (
                          <>
                            <Card.Link
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i className="fab fa-facebook"></i>
                            </Card.Link>
                            <Card.Link
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i className="fab fa-linkedin"></i>
                            </Card.Link>
                            <Card.Link
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i className="fab fa-instagram"></i>
                            </Card.Link>
                          </>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              )}
            </Row>
            <Row className="g-4">
              {member?.data?.employee_lists?.map(
                ({ name, image, designation, social_icon, slug }) => (
                  <Col sm={6} lg={3} className="image-top">
                    <Card className="bg-transparent ">
                      <Card.Img
                        variant="top"
                        src={`${baseURL}${image}`}
                        className="member-image rounded p-3 mx-auto"
                      />
                      <Card.Body className="text-center">
                        <Link
                          to={`/a-team/${slug}`}
                          className="text-decoration-none"
                        >
                          <Card.Title className="members-name-title h-50 text-decoration-none">
                            {name}
                          </Card.Title>
                        </Link>
                        <Card.Text
                          as={"h6"}
                          className="text-white mt-3 designation"
                        >
                          {designation}
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className="border-0 social-icons text-center">
                        {social_icon.length ? (
                          social_icon?.map(({ id, title, icon, url }) => (
                            <Card.Link
                              key={id}
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i
                                className={`fab fa-${title.toLowerCase()}`}
                              ></i>
                            </Card.Link>
                          ))
                        ) : (
                          <>
                            <Card.Link
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i className="fab fa-facebook"></i>
                            </Card.Link>
                            <Card.Link
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i className="fab fa-linkedin"></i>
                            </Card.Link>
                            <Card.Link
                              href="#"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="m-3"
                            >
                              <i className="fab fa-instagram"></i>
                            </Card.Link>
                          </>
                        )}
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              )}
            </Row>
          </div>
        </div>
        {/* employee end  */}
      </Container>
    </div>
  );
};

export default React.memo(ATeam);
