import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContactUs from '../../components/ContactUs/ContactUs';
import Trending from '../../components/Trending/Trending';
import BlogsBanner from '../Blogs/BlogsBanner/BlogsBanner';
import LatestBlogCard from '../Blogs/LatestBlogCard/LatestBlogCard';
import "./BlogCategory.scss";

const BlogCategory = () => {
    const { blog_category_slug } = useParams();
    const [blogsList, setBlogsList] = useState({});

    useEffect(() => {
        fetch(`https://digitalbackend.techsistltd.com/blog_list/${blog_category_slug}/`)
            .then(res => res.json())
            .then(data => setBlogsList(data?.data))
    }, [blog_category_slug])

    return (
        <>
            <BlogsBanner>{blogsList?.category_title}</BlogsBanner>

            <div className="container-fluid">
                <div className="container py-5">
                    {blogsList?.blog_list?.length === 0 ? <p className=' display-6 py-5' style={{ textAlign: "center" }}>Blogs Not Found!!</p> : <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                        {blogsList?.blog_list?.map((blog, index) => <LatestBlogCard
                            key={blog.id}
                            blog={blog}
                        ></LatestBlogCard>)}

                    </div>}
                </div>
            </div>

            <Trending></Trending>
            <ContactUs></ContactUs>
        </>
    );
};

export default BlogCategory;