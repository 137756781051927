import React from 'react';
import contributor from "../../../assets/images/contributors.png";
import ContributorCard from './ContributorCard/ContributorCard';
import "./TopContributors.scss";

const TopContributors = ({ blogs }) => {

    return (
        <div className="container-fluid" id='top-contributors-container'>
            <div className="container py-5 mb-5">
                <div className="row g-4 align-items-center">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-8 ">
                        <img src={contributor} alt="" className='img-fluid top-contributors-img' />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                        <div className='contributor-container'>
                            <h1 className='top-contributors-header-title pb-4'>Top Contributors</h1>
                            <div className="row row-cols-1 row-cols-md-1 g-3  contributor-items-container align-items-center">
                                {blogs?.top_contributer?.map((contributor, index) => < ContributorCard

                                    key={contributor?.id}
                                    contributor={contributor}

                                >{contributor}</ContributorCard>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TopContributors;