import React from 'react';
import TeamCard from '../TeamCard/TeamCard';

const TeamTopMember = ({ top_member, reverse }) => {
    return (
        <div className="row  mx-auto pb-3" style={{ flexDirection: reverse }}>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <TeamCard
                    top_member={top_member}

                ></TeamCard>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">

            </div>
        </div>
    );
};

export default TeamTopMember;