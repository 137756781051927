import React from "react";
import HTMLFlipBook from "react-pageflip";
import "../../styles/portfolio/PageFlip.scss";

const baseURL = "https://digitalbackend.techsistltd.com";

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      <img src={props.children} alt="" />
    </div>
  );
});

const PageFlip = ({ pfolio }) => {
  return (
    <div className="page-flip my-3">
      <HTMLFlipBook width={400} height={600} showCover={true}>
        <div className="page book-cover">
          <img
            src={`${baseURL}${pfolio?.data?.image}`}
            alt=""
            className="fluid w-100"
          />
        </div>

        {pfolio &&
          pfolio?.data?.page_image.page?.map(({ page_image }) => (
            <div className="page">
              <img src={page_image} alt="" className="fluid " />
            </div>
          ))}
      </HTMLFlipBook>
    </div>
  );
};

export default PageFlip;
