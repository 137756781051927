import React, { useState } from "react";
import { Col, Card } from "react-bootstrap";
import ServiceCategories from "./ServiceCategories";
import "../../pages/Services2/Services2.scss";
import ClampLines from "react-clamp-lines";
import "../../pages/Services2/ServiceCol.scss";

const baseURL = "https://digitalbackend.techsistltd.com";

const ServiceCol = ({
  key,
  serviceDesc,
  activeTab,
  setActiveTab,
  setSubServices,
}) => {
  const { title, icon, description, service_sub_description } = serviceDesc;
  const handleActive = () => {
    console.log("clicked");
    setSubServices(service_sub_description);
  };
  return (
    <Col id="service-col">
      <Card className="h-100 services-card">
        <Card.Img
          src={`${baseURL}${serviceDesc?.icon}`}
          alt=""
          className="card-icon"
        />
        <Card.Body>
          <Card.Title
            className="text-center m-md-3 card-title"
            onClick={handleActive}
          >
            {title}
          </Card.Title>
          <ClampLines
            text={description}
            id="custom"
            lines={3}
            moreText="Read More"
            lessText="Read Less"
            className="learn-more"
            ellipsis="..."
            innerElement="p"
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ServiceCol;
