import React from "react";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
// imported react-bootstrap components
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

// Header stylesheet
import "../../styles/common/Header.scss";

// techsist brand logo
import techsistLogo from "../../assets/images/techsist logo.png";

const Header = () => {
  return (
    // copied from react-bootstrap documentation
    <Navbar
      collapseOnSelect
      expand="lg"
      className="header py-3 "
      variant="dark"
      sticky="top"
      style={{ backgroundColor: "black" }}
    >
      <Navbar.Brand eventKey="1" as={Link} to="/">
        <img className="img-fluid" src={techsistLogo} alt="techsist_logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="m-lg-0 m-md-0 m-sm-2 toggle-btn-header" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto pe-lg-5 ps-sm-2">
          <Nav.Link eventKey="2" as={Link} to="/home" className="me-3 link">
            Home
          </Nav.Link>
          <NavDropdown title="Techsist" id="techsist" className="me-3 link">
            <NavDropdown.Item as={Link} to="about" eventKey="3">
              About
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="a-team" eventKey="4">
              A Team
            </NavDropdown.Item>
            {/* <NavDropdown.Item as={Link} to="portfolio" eventKey="5">
              Portfolio
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="gallery" eventKey="6">
              Gallery
            </NavDropdown.Item> */}
          </NavDropdown>
          {/* <Nav.Link
            as={Link}
            to="services"
            className="me-3 link"
            eventKey="services"
          >
            Services
          </Nav.Link> */}
          <NavDropdown title="Industry" id="techsist" className="me-3 link">
            <NavDropdown.Item
              href="http://it.techsistltd.com/"
              target="_blank"
              eventKey="7"
            >
              IT Services
            </NavDropdown.Item>
            <NavDropdown.Item
              href="http://business.techsistltd.com/"
              target="_blank"
              eventKey="8"
            >
              Business Services
            </NavDropdown.Item>
            <NavDropdown.Item
              href="http://digital.techsistltd.com/"
              target="_blank"
              eventKey="9"
            >
              Digital Services
            </NavDropdown.Item>
            <NavDropdown.Item
              href="http://lifestyle.techsistltd.com/"
              target="_blank"
              eventKey="10"
            >
              Lifestyle Services
            </NavDropdown.Item>
            <NavDropdown.Item
              href="http://studio.techsistltd.com/"
              target="_blank"
              eventKey="11"
            >
              Studio Services
            </NavDropdown.Item>
            <NavDropdown.Item
              href="http://event.techsistltd.com/"
              target="_blank"
              eventKey="12"
            >
              Event Services
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link
            as={Link}
            to="solutions"
            className="me-3 link"
            eventKey="solutions"
          >
            Solutions
          </Nav.Link>
          <Nav.Link as={Link} to="products" className="me-3 link" eventKey="13">
            Products
          </Nav.Link>
          {/* archives section  */}
          <NavDropdown title="Archives" id="techsist" className="me-3 link">
            {/* <NavDropdown.Item as={Link} to="/rnd" eventKey="16">
              R&D
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/blogs" eventKey="17">
              Blogs
            </NavDropdown.Item> */}
            <NavDropdown.Item as={Link} to="/portfolio" eventKey="18">
              Portfolio
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/gallery" eventKey="19">
              Gallery
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="careers" className="me-3 link" eventKey="14">
            Careers
          </Nav.Link>
          <Nav.Link as={Link} to="contact" className="me-3 link" eventKey="15">
            Contact
          </Nav.Link>
          <Nav.Link
            href="http://erp.techsistltd.com/"
            className="link"
            eventKey="16"
          >
            <FiLogIn style={{ fontSize: "20px" }} />
            {/* <a
              href="http://erp.techsistltd.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FiLogIn style={{ fontSize: "20px" }} />
            </a> */}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
