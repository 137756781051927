import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import "./Services2.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ServiceCategories from "../../components/services/ServiceCategories";
import ServicesTab from "../../components/services/ServicesTab";

const Services2 = () => {
  const baseURL = "https://digitalbackend.techsistltd.com";
  const [servicesNew, setServicesNew] = useState({});
  const [subServices, setSubServices] = useState([]);

  const location = useLocation();
  const slug = location.state?.slug || undefined;

  useEffect(() => {
    // API fetching from service_lists endpoint
    // for avoiding error, here using try, catch block
    fetch(`${baseURL}/service_lists/`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setServicesNew(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div id="services2">
      {/* Service Introduction Start */}
      <div className="py-5 mb-5 parallax-effect-services">
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={6}>
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <h3 className="title">{servicesNew?.data?.title} </h3>
                <p className="text-white mt-5 text-start">
                  {servicesNew?.data?.short_description}
                </p>
              </AnimationOnScroll>
            </Col>
            <Col md={1}></Col>
            <Col md={5}>
              <AnimationOnScroll animateIn="animate__fadeInRight">
                <img src={`${baseURL}${servicesNew?.data?.image}`} alt="" />
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Service Introduction End */}

      {/* Our Service Card Section Start */}
      <Container>
        <Row className="mb-5">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={slug ? slug : "it-services"}
            activeKey={slug}
          >
            <Row>
              <Col sm={3}>
                {/* Our Service Introduction Start */}
                <div>
                  <h4 className="service-title">{servicesNew?.data?.title2}</h4>
                  <p className="text-start text-white">
                    {servicesNew?.data?.description}
                  </p>
                </div>
                {/* Our Service Introduction End */}
                <Nav variant="pills" className="flex-row services-nav">
                  {servicesNew?.data?.service_group.map(({ title, slug }) => (
                    <Nav.Item
                      id={slug}
                      className="w-100"
                      onClick={() => setSubServices([])}
                    >
                      <Nav.Link eventKey={slug}>
                        <h6 className="font-size text-white">
                          <svg
                            width="46"
                            height="45"
                            viewBox="0 0 46 45"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.0664062 8C0.0664062 3.58172 3.64813 0 8.06641 0H37.1908C41.6091 0 45.1908 3.58172 45.1908 8V37C45.1908 41.4183 41.6091 45 37.1908 45H8.06641C3.64813 45 0.0664062 41.4183 0.0664062 37V8Z"
                              fill="#3F69FA"
                            />
                            <path
                              d="M22.6286 15.25C18.3474 15.25 14.8786 18.7188 14.8786 23C14.8786 27.2812 18.3474 30.75 22.6286 30.75C26.9099 30.75 30.3786 27.2812 30.3786 23C30.3786 18.7188 26.9099 15.25 22.6286 15.25Z"
                              fill="white"
                            />
                          </svg>
                          <span className="ms-3">{title}</span>
                        </h6>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content className="services-tab-content">
                  {subServices?.length ? (
                    <ServiceCategories subServices={subServices} />
                  ) : (
                    <>
                      {servicesNew?.data?.service_group?.map(
                        ({ title, slug, service_description }) => (
                          <Tab.Pane eventKey={slug}>
                            <ServicesTab
                              setSubServices={setSubServices}
                              service_description={service_description}
                            />
                          </Tab.Pane>
                        )
                      )}
                    </>
                  )}
                </Tab.Content>
              </Col>
            </Row>
            {/* Second Section */}
          </Tab.Container>
        </Row>
      </Container>
      {/* Our Service Card Section End */}

      {/* Our Quality Start */}
      <Container className="my-5 pt-5">
        <div className="text-center my-5">
          <h2 className="py-3 fw-bold title">
            {servicesNew?.data?.quality?.title}
          </h2>
          <p className="text-white">
            {servicesNew?.data?.quality?.description}
          </p>
        </div>
        <Row className="d-flex align-items-center">
          <Col md={4} className="text-center">
            {servicesNew?.data?.quality?.quality_description?.map(
              ({ id, title, description }, index) => {
                if (index % 2 === 0) {
                  return (
                    <div key={id} className="py-3">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33.3125 0.875H4.4375C2.22266 0.875 0.5 2.67969 0.5 4.8125V33.6875C0.5 35.9023 2.22266 37.625 4.4375 37.625H33.3125C35.4453 37.625 37.25 35.9023 37.25 33.6875V4.8125C37.25 2.67969 35.4453 0.875 33.3125 0.875Z"
                          fill="#0F43F9"
                        />
                      </svg>

                      <h6 className="my-3 fw-bold">{title}</h6>
                      <p className="small text-white">{description}</p>
                    </div>
                  );
                }
              }
            )}
          </Col>
          {/* mid image */}
          <Col
            md={4}
            className="d-flex align-items-center justify-content-center px-3"
          >
            <img
              className="img-fluid"
              src={`${baseURL}${servicesNew?.data?.quality?.image}`}
              alt="mobile"
            />
          </Col>
          <Col md={4} className="text-center">
            {servicesNew?.data?.quality?.quality_description?.map(
              ({ id, title, description }, index) => {
                if (index % 2 !== 0) {
                  return (
                    <div key={id} className="py-3">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33.3125 0.875H4.4375C2.22266 0.875 0.5 2.67969 0.5 4.8125V33.6875C0.5 35.9023 2.22266 37.625 4.4375 37.625H33.3125C35.4453 37.625 37.25 35.9023 37.25 33.6875V4.8125C37.25 2.67969 35.4453 0.875 33.3125 0.875Z"
                          fill="#0F43F9"
                        />
                      </svg>
                      <h6 className="my-3 fw-bold">{title}</h6>
                      <p className="small text-white">{description}</p>
                    </div>
                  );
                }
              }
            )}
          </Col>
        </Row>
      </Container>
      {/* Our Quality End */}
    </div>
  );
};

export default Services2;
