import React from 'react';
import "./ProfileDescription.scss";
import pp from "../../../../assets/images/profile-pic.png";
import { FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';

const ProfileDescription = ({ team_member }) => {

    return (
        <div className="container-fluid" id='profile-desc'>
            <div className="container pt-5 ">
                <div className="row ">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 p-3 ">
                        <div className="description-container p-3 pt-3 ">
                            {/* <h2 className='text-center pb-3'>Description</h2> */}
                            <div className="profile-details table-responsive">

                                <table className="table table-borderless ">

                                    <tbody >

                                        <tr>
                                            <td className='table-title-txt'>Emp ID</td>
                                            <td className='table-inner-txt'>{team_member?.employee_id}</td>
                                        </tr>
                                        <tr>
                                            <td className='table-title-txt'>Designation</td>
                                            <td className='table-inner-txt'>{team_member?.designation}</td>
                                        </tr>

                                        <tr>
                                            <td className='table-title-txt'>EMail</td>
                                            <td className='table-inner-txt'>{team_member?.email}</td>
                                        </tr>

                                        <tr>
                                            <td className='table-title-txt'>Phone No</td>
                                            <td className='table-inner-txt'>{team_member?.phone}</td>
                                        </tr>

                                        <tr>
                                            <td className='table-title-txt'>About</td>
                                            <td className='table-inner-txt'>{team_member?.about} </td>
                                        </tr>
                                        <tr>
                                            <td className='table-title-txt'>Experience</td>
                                            <td className='table-inner-txt'>{team_member?.experience}</td>
                                        </tr>

                                        <tr>
                                            <td className='table-title-txt'>Portfolio</td>
                                            <td className='table-inner-txt'>{team_member?.portfolio}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 p-3 ">

                        <div className="card profile-card" >
                            <img src={team_member?.image_url} className="card-img-top profile-img" alt="..." />
                            <div className="card-body">
                                <h5 className="name">{team_member?.name}</h5>
                                <div className=''>
                                    <a href="/" target={"_blank"} ><FaInstagram className='social-icon me-2'></FaInstagram></a>
                                    <a href="/" target={"_blank"} ><FaFacebook className='social-icon me-2'></FaFacebook></a>
                                    <a href="/" target={"_blank"} ><FaLinkedin className='social-icon' ></FaLinkedin></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default ProfileDescription;