import React from 'react';
import "./OurRecentProjectCard.scss";
import img1 from "../../../../assets/images/project-img/project-ss.png";
import img2 from "../../../../assets/images/project-img/project-img-2.png";

const OurRecentProjectCard = ({ project }) => {
    return (
        <div className="row g-5 pt-lg-5 p-3" id='recent-project-card' >
            <div className="col-12 col-sm-12 col-md-12 col-lg-12  recent-project-column px-3">
                <div className="project-img-card-1 h-100 p-lg-5">
                    <img src={project?.image_url} alt="" className='img-fluid' style={{ maxHeight: "500px" }} />
                    <h1 className='project-title pt-lg-5'>{project?.title}</h1>
                </div>
            </div>
            {/* <div className="col-4 col-sm-4 col-md-4 col-lg-4 p-lg-3 ">
                <div className="project-img-card-2 h-100 p-lg-3">
                    <img src={img2} alt="" className='img-fluid' />
                    <h1 className='project-title '>{project?.title}</h1>
                </div>

            </div> */}
        </div>
    );
};

export default OurRecentProjectCard;