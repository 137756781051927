import React from 'react';
import "./ContributorBlogCard.scss";
import img from "../../../assets/images/contributor-blog-card.png";
import { Link } from 'react-router-dom';

const ContributorBlogCard = ({ contributor_project }) => {
    return (
        <div id='rnd-contributor-blog-card' className="col">
            <div className="card h-100 rnd-contributor-blog-card">
                <img src={img} className="card-img-top mb-2 contributor-blog-img" alt="..." />
                <div className=" px-0 ">
                    {/* <Link to={`/rnd/${contributor_project?.slug}`}><h5 className="">{contributor_project?.title} </h5></Link> */}
                    <Link to={`/rnd/${contributor_project?.slug}`}><h5 className="">{"Taseen Bappi"} </h5></Link>

                </div>
            </div>
        </div>
    );
};

export default ContributorBlogCard;