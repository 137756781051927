import React from 'react';
import projectPic from "../../../../assets/images/project-pic.png";

const BlogRoles = ({ children, team_member }) => {

    return (
        <>
            <div className="container-fluid pt-4" id='project-rules' >
                <div className="container  project-rules-container my-5">
                    <h2 className='text-center pb-3'>{children}</h2>
                    <div className="project-roles-inner-container">
                        {/* blogs role section  */}
                        {team_member?.blog_role_details?.map((project, index) => <div className="row pt-4 blog-project-card" key={project?.id}>

                            <div className="col-12 col-sm-12 col-md-4 col-lg-5 p-3 ">
                                <img src={projectPic} alt="" className='img-fluid' />
                            </div>

                            <div className="col-12 col-sm-12 col-md-3 col-lg-3 ms-5 mt-3">
                                <div className="profile-details table-responsive">

                                    <table className="table table-borderless ">

                                        <tbody>

                                            <tr>
                                                <td className='table-title-txt'>Project Name</td>
                                                <td className='table-inner-txt'>{project?.name}</td>
                                            </tr>

                                            <tr>
                                                <td className='table-title-txt'>Key Role</td>
                                                <td className='table-inner-txt'>{project?.key_role}</td>
                                            </tr>

                                            <tr>
                                                <td className='table-title-txt'>Contribution</td>
                                                <td className='table-inner-txt'>{project?.contribution}</td>
                                            </tr>

                                            <tr>
                                                <td className='table-title-txt'>Project Time</td>
                                                <td className='table-inner-txt'>{project?.project_time}</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-5 col-lg-3 mt-4 ">
                                <p className='about-title'>About</p>
                                <p className='about-details'>{project?.about}</p>

                            </div>

                        </div>)}
                    </div>

                </div>
            </div>

        </>
    );
};

export default BlogRoles;