import React from "react";

const NotFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center text-center vh-100">
      <h1 className="fw-bold display-1" style={{ color: "red" }}>
        OOPS!!! <br />
        404!!! Not Found.
      </h1>
    </div>
  );
};

export default NotFound;
