import React from 'react';

const Footer = () => {
  return (
    <footer className='py-5' style={{ backgroundColor: '#4040401c' }}>
      <p className='text-white text-center'>Copyright © 2021. Techsist. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
