import React from 'react';
import "./TeamMemberCard.scss";
import memberbg from "../../../../assets/images/team/member-bg.svg";
import { FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';

const TeamMemberCard = ({ member }) => {
    const baseUrl = "https://digitalbackend.techsistltd.com";

    return (
        <div className="col" id='member-card'>
            <div className="card h-100 member-card-main">
                <div className="card-body member-card-body">
                    <img src={baseUrl + member?.image} className="img-fluid pb-3" alt="..." />
                    <h1 className="card-title member-card-name">{member?.name}</h1>
                    <h5 className="card-text member-card-designation">{member?.designation}</h5>
                    <div className=''>
                        <a href="/" target={"_blank"} ><FaInstagram className='team-a-card-social-icon me-2'></FaInstagram></a>
                        <a href="/" target={"_blank"} ><FaFacebook className='team-a-card-social-icon me-2'></FaFacebook></a>
                        <a href="/" target={"_blank"} ><FaLinkedin className='team-a-card-social-icon' ></FaLinkedin></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamMemberCard;