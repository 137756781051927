import React from 'react';
import "./ContributorCard.scss";
import img from "../../../../assets/images/contributor-img.png";

const ContributorCard = ({ contributor, remainingContributor }) => {


    return (
        <div className='col' id='contributor-card' onClick={() => remainingContributor(contributor.id)} role={"button"}>
            <div className="card flex-row align-items-center contributor-card-inner">
                <img src={contributor?.image_url} alt="" className='img-fluid contributor-img' />
                <div className="div">
                    <h5 className='contributor-name'>{contributor?.created_by}</h5>
                    <p className='contributor-total-number'>{contributor?.number_of_blog}</p>
                </div>
            </div>
        </div>
    );
};

export default ContributorCard;