import React from 'react';
import "./RndBlogCard.scss";
import img from "../../../../assets/images/rnd-card.png";
import author from "../../../../assets/images/blog-autor-pic.png";
import { Link } from 'react-router-dom';

const RndBlogCard = ({ project }) => {

    return (
        <div id='rnd-blog-card' className="col">
            <div className="card h-100 rnd-blog-card">
                <img src={project?.image_url} className="card-img-top" alt="..." />
                <div className="card-body px-0 ">
                    <Link to={`/rnd/${project?.slug}`}><h5 className="">{project?.title}</h5></Link>
                    <div className='author-info pb-2'>
                        <img src={project?.created_by?.image_url} className="img-fluid rounded-circle" alt="" />
                        <p>{project?.created_by?.name}</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default RndBlogCard;