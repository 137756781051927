import React from "react";
import "../../styles/home/Services.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";

// Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// Swiper core and required modules
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";

// Swiper modules
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const Services = ({ services }) => {
  const baseURL = "https://digitalbackend.techsistltd.com";
  return (
    <section id="services" className="text-center my-5">
      <div className="service-carousel">
        <AnimationOnScroll
          animateIn="animate__zoomIn"
          animateOut="animate__zoomOut"
        >
          <h2 className="title my-5 pt-sm-3">Industry Services</h2>
        </AnimationOnScroll>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          initialSlide={2}
          centeredSlides={true}
          preloadImages={true}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 150,
            modifier: 1,
            slideShadows: true,
          }}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          loop={true}
          simulateTouch={false}
        >
          {services?.data?.service_group?.map(
            ({ title, slug, image }, index) => (
              <SwiperSlide key={index} className="pb-4">
                {/* window.location="http://event.techsistltd.com/" */}
                <Link className="text-decoration-none" to="" state={{ slug }}>
                  <figure className="p-3">
                    <img src={`${baseURL}${image}`} alt={title} />
                    <h3 className="my-lg-3 py-3">{title}</h3>
                  </figure>
                </Link>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>
    </section>
  );
};

export default Services;
