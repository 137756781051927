import React, { useEffect, useState } from 'react';
import ContactUs from '../../components/ContactUs/ContactUs';
import Trending from '../../components/Trending/Trending';
import "./RND.scss";
import RndBanner from './RndBanner/RndBanner';
import RndBlog from './RndBlog/RndBlog';
import RndContributor from './RndContributor/RndContributor';
// imported Spinner from react-loader-Spinner
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Triangle } from "react-loader-spinner";

const RND = () => {

    const [rndArchives, setRndArchives] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        setIsLoading(true);
        fetch("https://digitalbackend.techsistltd.com/archive_details_list/")
            .then(res => res.json())
            .then(data => setRndArchives(data?.data))
            .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) {
        return <div
            style={{ height: "80vh", width: "100%" }}
            className="d-flex align-items-center justify-content-center"
        >
            <Triangle color="#237bb3" height={80} width={80} />
            Please wait... <br />
            R&D is Loading...
        </div>
    }

    return (
        <>
            <RndBanner>{rndArchives}</RndBanner>
            <RndBlog rndArchives={rndArchives}></RndBlog>
            <RndContributor rndArchives={rndArchives}></RndContributor>
            <Trending></Trending>
            <ContactUs></ContactUs>

        </>
    );
};

export default RND;