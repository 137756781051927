import React, { useEffect, useState } from 'react';
import "./Profile.scss";
import bg from "../../../assets/images/blog-banner.svg";
import ProfileDescription from './ProfileDescription/ProfileDescription';
import ProjectRules from './ProjectRules/ProjectRules';
import ContactUs from '../../../components/ContactUs/ContactUs';
import { useParams } from 'react-router-dom';
// imported Spinner from react-loader-Spinner
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Triangle } from "react-loader-spinner";
import BlogRoles from './BlogsRoles/BlogRoles';

const Profile = () => {

    const { team_member_slug } = useParams();
    const [team_member, setTeam_member] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setIsLoading(true);
        fetch(`https://digitalbackend.techsistltd.com/employee_profile_details/${team_member_slug}/`)
            .then(res => res.json())
            .then(data => setTeam_member(data?.data))
            .finally(() => setIsLoading(false))
    }, [team_member_slug])

    if (isLoading) {
        return <div
            style={{ height: "80vh", width: "100%" }}
            className="d-flex align-items-center justify-content-center"
        >
            <Triangle color="#237bb3" height={80} width={80} />
            Please wait... <br />
            R&D is Loading...
        </div>
    }
    const style = {
        backgroundImage: `url(${bg})`,
        width: '100%',
        height: "100%",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

    }

    return (
        <>
            <div className='container-fluid py-5 text-center' id='profile-banner' style={style}>
                <h1 className='text-white display-5 py-5'>{team_member?.name}</h1>
            </div>

            <ProfileDescription team_member={team_member}></ProfileDescription>
            <ProjectRules team_member={team_member}>{"Projects and Roles"}</ProjectRules>
            <BlogRoles team_member={team_member}>{"Blogs"}</BlogRoles>

            <ContactUs></ContactUs>
        </>
    );
};

export default Profile;