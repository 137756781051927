import React from 'react';
import './TeamCard.scss';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import emp1 from "../../../../assets/images/team/epm-1.png";
import { FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';

const TeamCard = ({ top_member }) => {
    const baseUrl = "https://digitalbackend.techsistltd.com";
    return (
        <div id='team-card'>
            <Card className='team-a-card'>
                <div className="team-a-card-inner">

                    <img src={baseUrl + top_member?.image} className="top-emp-img img-fluid" alt='' />

                    <Card.Body>
                        <Card.Title className='team-a-card-name'>{top_member?.name}</Card.Title>
                        <Card.Text className='team-a-card-designation'>
                            {top_member?.designation}
                        </Card.Text>
                        <Button className='team-a-card-btn'>Read More</Button>
                        <div className='pt-3'>
                            <a href="/" target={"_blank"} ><FaInstagram className='team-a-card-social-icon me-2'></FaInstagram></a>
                            <a href="/" target={"_blank"} ><FaFacebook className='team-a-card-social-icon me-2'></FaFacebook></a>
                            <a href="/" target={"_blank"} ><FaLinkedin className='team-a-card-social-icon' ></FaLinkedin></a>
                        </div>
                    </Card.Body>
                </div>
            </Card>
        </div>
    );
};

export default TeamCard;