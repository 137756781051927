import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import "../../styles/home/Projects.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import InstagramFeed from "react-ig-feed";
//instagram feed css
import "react-ig-feed/dist/index.css";

const Projects = ({ flagship_project }) => {
  console.log(flagship_project);
  const baseURL = "https://digitalbackend.techsistltd.com";

  return (
    <Container className="flagship-projects-container">
      <div id="flagship-projects" className="">
        <AnimationOnScroll
          animateIn="animate__zoomIn"
          animateOut="animate__zoomOut"
        >
          {/* <h2 className="title mt-5 py-5  text-center">Socials</h2> */}
        </AnimationOnScroll>
        {/* <Row xs={1} md={3} className="cards g-5">
          {flagship_project?.map(({ title, logo, url, id }) => (
            <Col key={id}>
              <a href={url} target="_blank" rel="noreferrer noopener">
                <Card className="border-0">
                  <img
                    src={`${baseURL}${logo}`}
                    className="card__image"
                    alt={title}
                  />
                  <div className="card__overlay">
                    <div className="card__header">
                      <svg
                        className="card__arc"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path />
                      </svg>
                      <div className="card__header-text">
                        <h3 className="card__title">{title}</h3>
                      </div>
                    </div>
                  </div>
                </Card>
              </a>
            </Col>
          ))}
        </Row> */}
        {/* <Row>
          <Col>
            <InstagramFeed
              token="IGQVJVWi1hVG5ZALUVCblVSOXhSRGlzWXY0ZATVwY1cwVHhXUjlub3VKQkdJeFZAKMlJkYmJwRTBYN2F5VGhOUWFiUVJESUJUdTNRWWQtTjE0UGtUYVRZAQ1RGQk9JMHc4YkNPTnRhWjR0WUtnWmI1OU9zNQZDZD"
              counter="12"
            />
          </Col>
        </Row> */}
      </div>
    </Container>
  );
};

export default Projects;
