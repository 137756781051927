import React from 'react';
import LatestBlogCard from '../LatestBlogCard/LatestBlogCard';
import './LatestBlogs.scss';
import { Link } from "react-router-dom";

const LatestBlogs = ({ blogs }) => {

    return (
        <div className='latest-blogs container-fluid' id='latest-blogs'>
            <div className="container py-5">
                <h1 className='title pb-5'>Latest</h1>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {blogs?.blog_list?.map((blog, index) => <LatestBlogCard
                        key={blog?.id}
                        blog={blog}
                    ></LatestBlogCard>)}

                </div>
                <Link to={"/allBlogs"}><button className='see-all-btn'>See all</button></Link>
            </div>

        </div>
    );
};

export default LatestBlogs;