import React, { useEffect, useState } from 'react';
import ContactUs from '../../../components/ContactUs/ContactUs';
import Trending from '../../../components/Trending/Trending';
import BlogsBanner from '../BlogsBanner/BlogsBanner';
import LatestBlogCard from '../LatestBlogCard/LatestBlogCard';

const AllBlogs = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        fetch("https://digitalbackend.techsistltd.com/all_blog_list/")
            .then(res => res.json())
            .then(data => setBlogs(data?.data))
    }, [])
    return (<>
        <BlogsBanner>{"All Blogs"}</BlogsBanner>
        <div className="container-fluid">
            <div className="container py-5">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">

                    {blogs?.map((blog, index) => <LatestBlogCard
                        key={blog?.id}
                        blog={blog}
                    ></LatestBlogCard>)}
                </div>
            </div>
        </div>
        <Trending></Trending>
        <ContactUs></ContactUs>
    </>

    );
};

export default AllBlogs;