import React from 'react';
import "./LatestBlogCard.scss";
import blogImg from "../../../assets/images/blog/blog-img.svg";
import author from "../../../assets/images/blog/author.png";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

const LatestBlogCard = ({ blog }) => {
    const data = blog?.description;
    return (
        <div id='blog-card' className="col">
            <div className="card h-100 blog-card">
                <img src={blog?.image_url} className="card-img-top " alt="..." />
                <div className="card-body px-0 ">
                    <Link to={`/blogsDetails/${blog?.slug}`}><h5 className="">{blog?.title} </h5></Link>
                    <div className='author-info pb-2'>
                        <img src={blog?.created_by?.image_url} className="img-fluid author-img" alt="" />
                        <p>{blog?.created_by?.name}</p>
                    </div>
                    {/* <div
                        dangerouslySetInnerHTML={{ __html: data }}
                    /> */}
                    <div>
                        {
                            parse(`${blog?.description?.slice(0, 120) + "..."}`)
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default LatestBlogCard;