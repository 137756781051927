import React from 'react';
import "./RndBanner.scss";
import bg from "../../../assets/images/blog-banner.svg";

const RndBanner = ({ children }) => {
    const style = {
        backgroundImage: `url(${children?.image_url})`,
        width: '100%',
        height: "360px",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

    }

    return (
        <div className='container-fluid pb-lg-5 text-center px-0' id='rnd-banner' >
            {/* <h1 className='text-white display-5 py-5'>{children?.title}</h1> */}
            <img src={children?.image_url} alt="" className='img-fluid' />
        </div>
    );
};

export default RndBanner;