import React from 'react';
import "./BlogsViewsCard.scss";
import img from "../../../../../assets/images/blog/most-view-blog.png";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const BlogsViewsCard = ({ top_blog }) => {

    return (
        <div id='blog-most-view' className="col">
            <div className="card h-100 blog-most-view mt-2">
                <img src={top_blog?.image_url} alt="" className='img-fluid' />
                <div className="card-body  ">
                    <p className='blog-most-view-cat'>{top_blog?.blog_category?.title}</p>
                    <Link to={`/blogsDetails/${top_blog?.slug}`}><h2 className='blog-most-view-cat-title'>{top_blog?.title}</h2></Link>
                    <p className='blog-most-view-cat-desc'>{
                        parse(`${top_blog?.description.slice(0, 120) + "..."}`)
                    }</p>

                </div>
            </div>
        </div>
    );
};

export default BlogsViewsCard;