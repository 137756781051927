import React from 'react';
import "./ContactForm.scss";
import { useForm } from "react-hook-form";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import Swal from 'sweetalert2';
import { useState } from 'react';

const ContactForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = data => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('company_name', data.company_name);
        formData.append('phone', data.phone);
        formData.append('message', data.message);
        formData.append('file', data.file[0]);
        axios.post('https://digitalbackend.techsistltd.com/portfolio_message/', formData)

            .then(function (response) {
                if (response.data.status) {
                    Swal.fire(
                        'Thank you!',
                        'An executive from Techsist will get in touch with you shortly!',
                        'success'
                    )
                    reset();
                }
                else {
                    alert(response.data.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => setIsLoading(false))

    };

    return (
        <div className='container-fluid' id='contact-form'>
            <div className="container py-lg-5">
                <h1 className='contact-form-title pb-lg-5'>We want to hear from you!</h1>
                <form className="row g-5 px-lg-5 py-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-6">
                        <label for="Name" className="form-label label-title">Name</label>
                        <input type="text" className=" input-field-style" id="Name" placeholder='Your Name' {...register("name", { required: true })} />
                    </div>

                    <div className="col-md-6">
                        <label for="Email" className="form-label label-title">Email</label>
                        <input type="email" className=" input-field-style" id="Email" placeholder='Enter Your Email' {...register("email", { required: true })} />
                    </div>

                    <div className="col-md-6">
                        <label for="Phone" className="form-label label-title">Phone</label>
                        <input type="text" className=" input-field-style" id="Phone" placeholder='Enter Your Phone Number' {...register("Phone", { required: true })} />
                    </div>
                    <div className="col-md-6">
                        <label for="Company" className="form-label label-title">Company</label>
                        <input type="text" className=" input-field-style" id="Company" placeholder='Ex: Pixel' {...register("company_name", { required: true })} />
                    </div>
                    <div className="col-md-12">
                        <label for="Message" className="form-label label-title">Message</label>
                        <textarea type="text" className=" input-field-style" id="Message" placeholder='Please describe your project' {...register("message", { required: true })} />
                    </div>
                    <div className="col-6 ">
                        <input type="file" style={{ display: "none" }} id="file" name="file" {...register("file")} />
                        <label htmlFor="file" className="form-label label-add-title mb-0">
                            + <span className="add-attachment">Add Attachment</span>
                        </label>

                    </div>

                    <div className="col-6 text-end">
                        <div className="send-container ">
                            <input
                                className="me-3 send-btn-style"
                                type="submit"
                                value={isLoading ? "Sending..." : "Submit"}
                            />
                            <div className="send-icon-holder text-center">
                                <FaArrowRight type="submit" className="arrow-icon"></FaArrowRight>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;