import React from 'react';
import Marquee from "react-fast-marquee";
import "./RndBlog.scss";
import bg from "../../../assets/images/rndblog.png";
import RndBlogCard from './RndBlogCard/RndBlogCard';

const RndBlog = ({ rndArchives }) => {

    return (
        <div className='container-fluid' id='rnd-blog-container'>
            <div className="container py-5 ">
                <div className="row ">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-3 p-3">
                        <img src={rndArchives?.thumbnail_image_url} alt="" className='img-fluid' style={{ maxHeight: "420px" }} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-9 px-4 pt-4">
                        <h1 className='feature-blog-title'>Techsist Introducted E-gate in Bangladesh</h1>
                        <div className=" pt-5 ">
                            <Marquee
                                pauseOnHover={true}
                                gradient={false}
                                direction={"left"}
                                style={{ width: "100%" }}
                            >
                                {rndArchives?.archieve_project_list?.map((project, index) => <RndBlogCard
                                    key={project?.id}
                                    project={project}
                                ></RndBlogCard>)}

                            </Marquee>


                        </div>
                        {/* <hr className='rnd-hr' /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RndBlog;