import React from 'react';
import "./TrendingCard.scss";
import trendImg from "../../../assets/images/trending-card.svg";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const TrendingCard = ({ trend }) => {
    // const n = trend?.description.slice(50)
    // console.log(n)
    // const data = parse(`${trend?.description}`);
    // console.log(data);
    return (
        <div className="col" id='trending-card'>
            <div className="card trending-card" >
                <div className="row g-0 align-items-center">
                    <div className="col-md-4">
                        <img src={trend?.image_url} className="img-fluid " alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="px-3">
                            <Link to={`/blogsDetails/${trend?.slug}`}><h5 className="trending-blog-title m-0">
                                {trend?.title}
                            </h5></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrendingCard;