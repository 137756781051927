import { useState, useEffect } from "react";
import { Col, Card, Row, Button } from "react-bootstrap";

const baseURL = "https://digitalbackend.techsistltd.com";

const ServiceCategories = ({ subServices }) => {
  return (
    <Row xs={1} md={2} className="g-4">
      {subServices?.map(({ icon, title, description }) => (
        <Card className="h-100 bg-transparent">
          <Card.Img src={`${baseURL}${icon}`} alt="" className="card-icon" />
          <Card.Body>
            <Card.Title className="text-center h-25 pb-5">{title}</Card.Title>
            <Card.Text className="text-white">{description}</Card.Text>
          </Card.Body>
          <Card.Footer className="text-center">
            <Card.Link href="#">Learn More</Card.Link>
          </Card.Footer>
        </Card>
      ))}
    </Row>
  );
};

export default ServiceCategories;
