import React, { useEffect, useState } from 'react';
import "./Trending.scss";
import TrendingCard from './TrendingCard/TrendingCard';

const Trending = () => {

    const [trending, setTrending] = useState({});

    useEffect(() => {
        fetch("https://digitalbackend.techsistltd.com/blog_list_details/")
            .then(res => res.json())
            .then(data => setTrending(data?.data))
    }, [])

    return (
        <div className='container-fluid'>
            <div className="container">
                <h1 className='title-2 text-center pb-5'>Trending</h1>
                <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3 g-4 py-3 align-items-center">
                    {trending?.trending_list?.map((trend, index) => <TrendingCard
                        key={trend?.id}
                        trend={trend}
                    ></TrendingCard>)}

                </div>
            </div>
        </div>
    );
};

export default Trending;