import React from 'react';
import Slider from 'react-slick';
import OurRecentProjectCard from './OurRecentProjectCard/OurRecentProjectCard';
import "./OurRecentProjects.scss";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const OurRecentProjects = ({ portfolio }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1
    };

    return (
        <div className='container-fluid' id='recent-project-container' >
            <div className="container py-5">
                <h1 className='title display-4 fw-bold text-center'>{portfolio?.data?.recent_project?.title}</h1>
                <p className='text-white'>{portfolio?.data?.recent_project?.description}</p>
                {/* <OurRecentProjectCard></OurRecentProjectCard> */}
                <Slider {...settings}>
                    {portfolio?.data?.recent_project?.project_description?.map((project, index) => <div key={project.id}>
                        <OurRecentProjectCard
                            project={project}
                        ></OurRecentProjectCard>
                    </div>)}
                    {/* <div>
                        <OurRecentProjectCard></OurRecentProjectCard>
                    </div> */}

                </Slider>
            </div>
        </div>
    );
};

export default OurRecentProjects;