import React from 'react';
import blogBanner from "../../../assets/images/blog-banner.svg";

const BlogsBanner = ({ children }) => {

    const style = {
        backgroundImage: `url(${children?.file_url})`,
        width: '100%',
        height: "100%",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

    }

    return (
        <div className='container-fluid text-center px-0' id='blogs-banner' >
            {/* <h1 className='text-white display-5 py-5'>{children?.title}</h1> */}
            <img src={children?.file_url} alt="" className='img-fluid' />
        </div>
    );
};

export default BlogsBanner;