import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../styles/products/Products.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Products = () => {
  const baseURL = "https://digitalbackend.techsistltd.com";
  const [products, setProducts] = useState({});
  useEffect(() => {
    // API fetching from products URL
    fetch(`${baseURL}/product_list/`)
      .then((response) => {
        // for avoiding error, here using try, catch block
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div id="products">
      <Container>
        {/* products page cover start */}
        <div className="products-banner">
          <Row className="flex-sm-row-reverse">
            <Col md={6}>
              <AnimationOnScroll animateIn="animate__fadeInRight">
                <img
                  className="img-fluid"
                  src={`${baseURL}${products?.data?.image}`}
                  alt="product_image"
                />
              </AnimationOnScroll>
            </Col>
            <Col md={6} className="d-flex align-items-center my-3">
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <div>
                  <h1 className="title product-title display-5 fw-bold text-center">
                    {products?.data?.title}
                  </h1>
                  <p className="service-description text-white">
                    {products?.data?.description}
                  </p>
                  <button className="btn btn-primary text-center d-block m-auto">
                    Coming Soon
                  </button>
                </div>
              </AnimationOnScroll>
            </Col>
          </Row>
        </div>
        {/* products page cover end */}

        {/* <div className="text-center my-5">
          <div className="my-5">
            <h1 className="display-5 fw-bold">
              {products?.data?.tech_product?.title}
            </h1>
            <p className="text-white text-center">
              {products?.data?.tech_product?.description}
            </p>
          </div>
        </div>
        {products?.data?.tech_product?.product.length === 0 ? (
          <Card style={{ width: "auto" }}>
            <Card.Body>
              <Card.Text as="h2" className="text-center">
                Products Coming Soon!
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          
          products?.data?.tech_product?.product?.map(
            ({ title, image }, index) => {
              if (index % 2 === 0) {
                return (
                  <Row>
                    <Col className="d-flex align-items-center">
                      <div>
                        <div>
                          <h3 className="display-5 fw-bold">{title}</h3>
                        </div>
                        <div className="my-5 py-5">
                          <a
                            href="https://www.facebook.com/techsistltd"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="primary"
                              className="me-3 button-style"
                            >
                              Purchase Product
                            </Button>
                          </a>
                          <Button variant="outline-primary">
                            Live Preview
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col className="my-5 py-5 d-flex align-items-center justify-content-center">
                      <img src={`${baseURL}${image}`} alt="qr code generator" />
                    </Col>
                  </Row>
                );
              } else {
                return (
                  <Row className="flex-sm-row-reverse">
                    <Col className="d-flex align-items-center">
                      <div>
                        <div>
                          <h3 className="display-5 fw-bold">{title}</h3>
                        </div>
                        <div className="my-5 py-5">
                          <a
                            href="https://www.facebook.com/techsistltd"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="primary"
                              className="me-3 button-style"
                            >
                              Purchase Product
                            </Button>
                          </a>
                          <Button variant="outline-primary">
                            Live Preview
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col className="my-5 py-5 d-flex align-items-center justify-content-center">
                      <img src={`${baseURL}${image}`} alt="qr code generator" />
                    </Col>
                  </Row>
                );
              }
            }
          )
        )} */}
      </Container>
    </div>
  );
};

export default Products;
