import React from "react";

// react-router-dom
import { Routes, Route } from "react-router-dom";

// imported Pages
import Home from "./pages/Home";
import About from "./pages/Techsist/About";
import ATeam from "./pages/Techsist/ATeam";
import Gallery from "./pages/Techsist/Gallery";
import Portfolio from "./pages/Techsist/Portfolio";
import Products from "./pages/Products";
import Solutions from "./pages/Solutions";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound/NotFound";

// common components (Header, Footer, Chatbot)
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

// stylesheet
import "./styles/App.scss";

// imported chatbot
import MessengerCustomerChat from "react-messenger-customer-chat";
import Services2 from "./pages/Services2/Services2";
import MeetingMinutes from "./pages/MeetingMinutes/MeetingMinutes";
import TeamA from "./pages/Techsist/TeamA/TeamA";
import Blogs from "./pages/Blogs/Blogs";
import BlogCategory from "./pages/BlogCategory/BlogCategory";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import RND from "./pages/RND/RND";
import Profile from "./pages/Techsist/Profile/Profile";
import AllBlogs from "./pages/Blogs/AllBlogs/AllBlogs";
import RndArchiveDetails from "./pages/RND/RndArchiveDetails/RndArchiveDetails";

const App = () => {
  return (
    <div className="app">
      <Header />
      {/* react-router start */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/meetingMinutes" element={<MeetingMinutes />} />
        <Route path="about" element={<About />} />
        <Route path="a-team" element={<ATeam />} />
        <Route path="/a-team/:team_member_slug" element={<Profile />} />
        <Route path="teamA" element={<TeamA />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="allBlogs" element={<AllBlogs />} />
        <Route path="/blogsCategory/:blog_category_slug" element={<BlogCategory />} />
        <Route path="/blogsDetails/:blog_slug" element={<BlogDetails />} />
        <Route path="rnd" element={<RND />} />
        <Route path="/rnd/:archive_project_slug" element={<RndArchiveDetails />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="services" element={<Services2 />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="products" element={<Products />} />
        <Route path="careers" element={<Careers />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* react-router end */}

      {/* chatbot start */}
      <MessengerCustomerChat pageId="430916870275544" appId="598336568062800" />
      {/* chatbot end */}
      <Footer />
    </div>
  );
};

export default App;
