import React from 'react';
import "./RndContributor.scss";
import ContributorCard from '../../Blogs/TopContributors/ContributorCard/ContributorCard';
import img from "../../../assets/images/contributor-img.png";
import ContributorBlogCard from '../ContributorBlogCard/ContributorBlogCard';
import { useState } from 'react';


const RndContributor = ({ rndArchives }) => {

    const [contributorDetails, setContributorDetails] = useState(rndArchives?.archieve_contributer[0]);

    const remainingContributor = (id) => {
        const contributor = rndArchives?.archieve_contributer?.find((cntb) => cntb.id === id);
        return setContributorDetails(contributor);
    }

    return (
        <div className="container-fluid" id='rnd-contributor'>
            <div className="container pb-5">
                <div className="row ">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-3 p-3">
                        <div className='rnd-contributor-container p-3'>
                            <h1 className='top-contributors-header-title pb-3'>Top Contributors</h1>
                            <div className="row row-cols-1 row-cols-md-1 g-3 pt-3 mx-auto contibutor-container-card">
                                {rndArchives?.archieve_contributer?.map((contributor, index) => <ContributorCard
                                    key={contributor?.id}
                                    contributor={contributor}
                                    remainingContributor={remainingContributor}
                                ></ContributorCard>)}


                            </div>
                        </div>
                    </div>

                    {/* rnd contributor details here */}
                    <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-3 ">
                        <div className='rnd-contributor-details p-3 '>
                            <h1 className='top-contributors-header-title'>R & D Contributions</h1>

                            <div className="row pt-4">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 ">
                                    <div className='col' id='contributor-card'>
                                        <div className="card flex-row align-items-center contributor-card-inner">
                                            <img src={contributorDetails?.image_url} alt="" className='img-fluid contributor-details-img' />
                                            <div className="div">
                                                <h5 className='contributor-name'>{contributorDetails?.name}</h5>
                                                <p className='contributor-total-number'>{contributorDetails?.number_of_blog}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contributor-details pt-3 table-responsive">
                                        <table className="table table-borderless ">

                                            <tbody>

                                                <tr>
                                                    <td className='table-title-txt'>Designation</td>
                                                    <td className='table-inner-txt'>{contributorDetails?.designation}</td>
                                                </tr>

                                                <tr>
                                                    <td className='table-title-txt'>EMail</td>
                                                    <td className='table-inner-txt'>{contributorDetails?.email}</td>
                                                </tr>

                                                <tr>
                                                    <td className='table-title-txt'>Phone No</td>
                                                    <td className='table-inner-txt'>{contributorDetails?.phone}</td>
                                                </tr>

                                                <tr>
                                                    <td className='table-title-txt'>About</td>
                                                    <td className='table-inner-txt'>{contributorDetails?.about}</td>
                                                </tr>
                                                <tr>
                                                    <td className='table-title-txt'>Experience</td>
                                                    <td className='table-inner-txt'>{contributorDetails?.experience}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-7 p-3 ">
                                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-3">
                                        {contributorDetails?.contributor_project_list?.map((contributor_project, index) =>
                                            <ContributorBlogCard
                                                key={contributor_project.id}
                                                contributor_project={contributor_project}
                                            ></ContributorBlogCard>)}


                                    </div>
                                    {contributorDetails?.contributor_project_list?.length > 6 && <div className='text-center'>
                                        <button className='view-all-btn mt-3 '>View All</button>
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default RndContributor;