import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import ContactUs from '../../../components/ContactUs/ContactUs';
import Trending from '../../../components/Trending/Trending';
import BlogsBanner from '../../Blogs/BlogsBanner/BlogsBanner';
import "./RndArchiveDetails.scss";

const RndArchiveDetails = () => {
    const { archive_project_slug } = useParams();
    const [archivesDetails, setBlogsDetails] = useState({});

    const data = archivesDetails?.description;

    useEffect(() => {
        fetch(`https://digitalbackend.techsistltd.com/archive_project_details/${archive_project_slug}/`)
            .then(res => res.json())
            .then(data => setBlogsDetails(data?.data))
    }, [archive_project_slug])

    return (
        <>
            <BlogsBanner></BlogsBanner>
            <div className="container-fluid" id='blog-details'>
                <div className="container py-5">
                    <h1 className='blog-details-title'>{archivesDetails?.title}</h1>
                    <div className="author-info-details py-3 d-flex align-items-center">
                        <img src={archivesDetails?.created_by?.image_url} alt="" className='img-fluid blog-author-img' />
                        <div className="author-txt ">
                            <p className="author-name mb-1">
                                {archivesDetails?.created_by?.name}
                            </p>
                            <p className="blog-date m-0">
                                {archivesDetails?.created_at}
                            </p>
                        </div>

                    </div>
                    <div className="blog-details-text pt-4 " >
                        {parse(`${archivesDetails?.description}`)}
                    </div>
                    {/* <div className="blog-details-text pt-4 " dangerouslySetInnerHTML={{ __html: data }}>

                    </div> */}
                    <hr className='details-blog-hr mt-3' />
                </div>
            </div>
            <Trending></Trending>
            <ContactUs></ContactUs>
        </>
    );
};

export default RndArchiveDetails;