import React from 'react';
import { Link } from 'react-router-dom';
import "./BlogCategory.scss";

const BlogCategory = ({ blogs }) => {

    return (
        <div id='blog-category' className="col ">
            <div className="card h-100 blog-category py-0">
                <h2>Category</h2>
                <div className="blog-catagory-items-container ">
                    <div className="card-body ps-0 ">
                        {blogs?.blog_category_list?.map((category, index) => <Link
                            to={`/blogsCategory/${category?.slug}`}
                            key={category?.id}
                        ><button className='blog-catagory-btn p-3 mb-2'

                        >{category?.title}</button></Link>)}


                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogCategory;