import React, { useState } from 'react';
import "./TeamA.scss";
import ateambg from "../../../assets/images/a-team-title-bg.svg";
import TeamCard from './TeamCard/TeamCard';
import TeamMemberCard from './TeamMemberCard/TeamMemberCard';
import anni from "../../../assets/images/annimation.gif";
import { useEffect } from 'react';
import TeamTopMember from './TeamTopMember/TeamTopMember';
import ContactUs from '../../../components/ContactUs/ContactUs';



const TeamA = () => {

    const [Ateam, setAteam] = useState({});

    const style = {
        backgroundImage: `url(${ateambg})`,
        width: '100%',
        height: "100%",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

    }

    useEffect(() => {
        fetch("https://digitalbackend.techsistltd.com/team_member_deatils/")
            .then(res => res.json())
            .then(data => setAteam(data.data))
    }, [])

    return (
        <div className='container-fluid p-0' id="teamA">
            <div className="">
                <div className=" py-5 " style={style}>
                    <h1 className='title text-center'>A TEAM</h1>
                    {/* <p className='a-team-sub-txt '>Brains Behind The Scene</p> */}
                    <h1 className="brain-behind text-center display-6 text-capitalize">Brains Behind The Scene</h1>
                </div>

                <div className="py-5 container">
                    {Ateam?.top_management_lists?.map((top_member, index) => <TeamTopMember
                        reverse={index % 2 ? "row-reverse" : ""}
                        top_member={top_member}
                        key={top_member?.id}
                    ></TeamTopMember>)}

                </div>

                <div className=" py-5 " style={style}>
                    <h1 className='title text-center py-3'>Members</h1>
                </div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 container mx-auto py-5">
                    {Ateam.employee_lists?.map((member, index) => <TeamMemberCard
                        key={member.id}
                        member={member}
                    ></TeamMemberCard>)}


                </div>

            </div>

            <ContactUs></ContactUs>
        </div >
    );
};

export default TeamA;